import React from 'react';
import {
  Added,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="June 2022" subnav="release-notes">
      <div id="June2022" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          Summer is arriving and so are some new features and icons for Uniform.
          June also brings a big change to the delivery of our React Native
          library. We have moved out of Jarvis and into a{' '}
          <Link href="https://github.com/hudl/rn-uniform">new repo</Link>. While
          Jarvis has been a great home for us, we believe becoming more
          independent will give us better control over releases. We've reset the
          version number to <code>v4.0.0</code> as this is a new package, but
          still in line with the <code>v4</code> web component library.
        </Paragraph>

        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.20.0"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases"
            />
            <div className={styles.changesList}>
              <Added>
                New Ethernet Warning icon to{' '}
                <Link href="/components/icons/technology/design">
                  technology icon set
                </Link>
                .
              </Added>
              <Added>
                The <code>className</code> prop to all components that didn't
                already accept it.
              </Added>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              version="4.0.0"
              versionUrl="https://github.com/hudl/rn-uniform/releases"
            />
            <div className={styles.changesList}>
              <Added>
                Uniform code from Jarvis has been migrated into our{' '}
                <Link href="https://github.com/hudl/rn-uniform">
                  new package
                </Link>
                .
              </Added>
              <Added>
                New Ethernet Warning icon to{' '}
                <Link href="/components/icons/technology/design">
                  technology icon set
                </Link>
                .
              </Added>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader libName="Figma Library" />
            <div className={styles.changesList}>
              <Added>
                New Ethernet Warning icon to{' '}
                <Link href="/components/icons/technology/design">
                  technology icon set
                </Link>
                .
              </Added>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />
            <div className={styles.changesList}>
              <Added>
                References to Jarvis have been updated with our{' '}
                <Link href="https://github.com/hudl/rn-uniform">
                  new package
                </Link>
                .
              </Added>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
